@nav-height: 24px;
// clientHeight + marginTop * 2
@header-height: 120px + 20px * 2;
.page-pipeline-list {
  height: calc(100% - @nav-height);
  padding: 0px 20px;
  background-color: white;
  & > .header {
    padding: 20px 0px;
    position: relative;
    .statistic {
      text-align: left;
      & > .item {
        & + .item {
          margin-left: 100px;
        }
        display: inline-block;
        & > .title {
          color: #4a4a4a;
          font-size: 16px;
          line-height: 16px;
        }
        & > .value {
          font-size: 24px;
          line-break: 34px;
          color: #1f1f1f;
          font-weight: 600;
        }
      }
    }
  }
  & > .list {
    height: calc(100% - @header-height - 8px);
    overflow: visible;
    table {
      table-layout: auto;
      width: 100%;
      tr {
        td,
        th {
          font-size: 16px;
          line-height: 26px;
          border-bottom: 1px solid #e6e6e6;
          text-align: left;
        }
        td {
          color: #000;
          word-break: break-word;
          padding: 8px 5px;
        }
        th {
          color: #4a4a4a;
          font-weight: 600;
          border-top: 1px solid #e6e6e6;
          padding: 8px 5px;
          background-color: white;
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

@ant-prefix: daas-ant;