/**
 * override css pollution from pulus micro frontend
 * TODO: this is not a good solution, will consider use antd prefixCls
 */
.daas-ant-cascader-dropdown {
  .daas-ant-cascader-menus {
    position: relative;
  }
}

.daas-ant-table {
  table {
    .daas-ant-table-thead {
      & > tr {
        & > th {
          background: #e7e7e7;
        }
      }
    }
  }

  .daas-ant-table-tbody {
    .daas-ant-table-row:hover {
      & > td {
        background-color: #ffffff;
      }
    }
  }
}

@ant-prefix: daas-ant;