.wrapper {
  display: flex;
  overflow: hidden;
}

.text {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  position: relative;
}

.text::before {
  content: '';
  height: calc(100% - 22px);
  float: right;
}

.text::after {
  content: '';
  width: 999vw;
  height: 999vw;
  position: absolute;
  box-shadow: inset calc(100px - 999vw) calc(21px - 999vw) 0 0 #fff;
  margin-left: -101px;
}

.btn {
  float: right;
  clear: both;
  margin-left: 10px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  color: #0046ff;
  cursor: pointer;
}

.btn::before {
  content: 'show more';
}

.exp {
  display: none;
}

.exp:checked + .text {
  -webkit-line-clamp: 999;
}

.exp:checked + .text::after {
  visibility: hidden;
}

.exp:checked + .text .btn::before {
  content: 'show less';
}

@ant-prefix: daas-ant;