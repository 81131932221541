.component-pipeline-setting {
  min-width: 800px;

  & > .nav {
    position: relative;
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 32px;

    .daas-ant-steps-horizontal {
      width: 50%;

      .daas-ant-steps-item-process {
        .daas-ant-steps-item-icon {
          background: #0046FF;
          border: 1px solid #0046FF;
        }
      }

      .daas-ant-steps-item-description {
        max-width: max-content;
      }
    }

    .nav-buttons {
      .daas-ant-btn {
        background: #FFFFFF;
        border: 1px solid #0046FF;
        border-radius: 6px;
        padding: 6px 10px;
        color: #0046FF;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
      }

      .button {
        cursor: pointer;
      }
    }
  }

  section.setting-step {
    display: none;

    &.active {
      display: block;
    }

    // bold label text
    .daas-ant-form {
      .daas-ant-form-item {
        .daas-ant-form-item-label {
          font-weight: 600;
        }
      }

      .daas-ant-select {
        width: 420px;
      }
    }

    &.select-data-destination {
      @section-title-height: 30px;

      .section-title {
        font-size: 22px;
        line-height: @section-title-height;
        font-weight: 600;
        margin-bottom: calc(@section-title-height / 2);
      }

      .category {
        @color: #4a4a4a;
        @form-item-margin-bottom: 24px;
        position: relative;
        // border: 1px solid @color;
        padding: calc(2px + @section-title-height) 0 0 0;
        margin-bottom: calc(12px + @form-item-margin-bottom);
        margin-top: calc(20px + @form-item-margin-bottom);

        &.error {
          border-color: #ff4d4f;
        }

        & > .header {
          position: absolute;
          top: calc(-@section-title-height / 2);
          // left: 8px;
          // padding: 0px 6px;
          background: white;
          color: @color;

          & > .title {
            line-height: @section-title-height;
            .section-title();
          }
        }

        & > .error-message {
          color: #ff4d4f;
        }

        .password-privateKey-select {
          display: flex;
          align-items: center;
          width: 120px;
          height: 32px;
        }

        .required::before {
          display: inline-block;
          margin-right: 4px;
          color: #ff4d4f;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
        }
      }
    }

  }
}

.save-pipeline-modal {
  .daas-ant-modal-header {
    border-color: #A4A4A4;
  }

  .daas-ant-modal-title {
    font-size: 20px;
  }


  .daas-ant-form-item-label {
    & > label {
      font-size: 16px;
    }
  }

  .daas-ant-input {
    border-color: #7C868F;
  }

  .modal-rule {
    font-size: 12px;

    .modal-rule-content {
      margin-left: 4px;
    }
  }

  .daas-ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    border-color: #A4A4A4;

    .daas-ant-btn {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #686868;
      padding: 6px 12px;
      border-color: #686868;
      border-radius: 6px;
      display: flex;
      align-items: center;
    }

    .daas-ant-btn-primary {
      background-color: #FFFFFF;
      color: #0046FF;
      border-color: #0046FF;
    }
  }

}


@ant-prefix: daas-ant;