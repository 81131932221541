.ruleMapperForTags {
  display: flex;
  :global {
    .daas-ant-btn {
      height: auto;
      background: #ffffff;
      border: 1px solid #686868;
      border-radius: 6px;
      color: #686868;
      line-height: 22px;
      padding: 6px 12px;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.ruleDom {
  display: flex;
}

.includesSpan {
  width: 120px;
  height: 32px;
  line-height: 32px;
  margin: 0 4px 0 0;
  padding: 0 7px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.deleteButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 11px;
}

:global {
  .session-tags-select {
    .daas-ant-select-selection-placeholder {
      width: 80%;
    }
  }
}

@ant-prefix: daas-ant;