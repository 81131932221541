.nav-list {
  display: inline-block;
  text-align: left;
  background-color: #f6f6f6;
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  vertical-align: top;
  & > .nav-item {
    display: inline-block;
    color: #4a4a4a;
    padding: 0px 12px;
    text-decoration: none;
    &:not(:last-child) {
      border-right: 1px solid #d9d9d9;
    }
    &.active {
      color: white;
      background: linear-gradient(180deg, #2d79ff 0%, #1556ff 100%);
    }
    &.closeable {
      position: relative;
      .close-icon {
        line-height: 12px;
        padding: 1px 3px;
        position: absolute;
        right: 0px;
        top: 0px;
      }
    }
  }
}

@ant-prefix: daas-ant;