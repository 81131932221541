/* ---------------------------------------------------------------------------- */
/* ------------------------------------Reset----------------------------------- */
/* ---------------------------------------------------------------------------- */

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
blockquote,
big,
body,
center,
canvas,
caption,
cite,
code,
command,
datalist,
dd,
del,
details,
dfn,
dl,
div,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
keygen,
label,
legend,
li,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
s,
samp,
section,
small,
span,
source,
strike,
strong,
sub,
sup,
table,
tbody,
tfoot,
thead,
th,
tr,
tdvideo,
tt,
u,
ul,
var {
  border: 0 none;
  font-size: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

table,
table td {
  padding: 0;
  border: none;
  //border-collapse: collapse;
}

article,
aside,
audio,
canvas,
command,
datalist,
details,
embed,
figcaption,
figure,
footer,
header,
hgroup,
keygen,
meter,
nav,
output,
progress,
section,
source,
video {
  display: block;
}

mark,
rp,
rt,
ruby,
summary,
time {
  display: inline;
}

input,
textarea {
  border: 0;
  padding: 0;
  margin: 0;
  outline: 0;
}

iframe {
  border: 0;
  margin: 0;
  padding: 0;
}

input,
textarea,
select {
  margin: 0;
  padding: 0px;
}

/* ---------------------------------------------------------------------------- */

/* ------------------------------------Html-Body------------------------------- */

/* ---------------------------------------------------------------------------- */

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  /* font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif; */
  /* font-weight: 400; */
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}
a:hover {
  opacity: 0.9;
  text-decoration: none;
}

blockquote {
  padding-left: 10px;
}

code {
  font-size: 1em;
}

h1,
h2,
h3,
h4 {
  font-size: 25px;
  line-height: 25px;
}

.clear:before,
.clear:after {
  display: table;
  content: '';
}
.clear:after {
  clear: both;
}

/* .expand-to-next-line {
  display: inline-block;
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-all;
  line-height: 1.2;
} */

/* ::-webkit-scrollbar { width: 2px; height: 2px;} */
