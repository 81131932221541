.row {
  display: flex;
  vertical-align: middle;
  margin-bottom: 4px;
}

.buttonTextOnly {
  .button();
  background-color: transparent;
  border-color: transparent;
  cursor: auto;
}

.button {
  background-color: #ffffff;
  border: 1px solid #7c868f;
  border-radius: 4px;
  width: 30px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  cursor: pointer;

  & + .button,
  & + .buttonTextOnly {
    margin-left: 4px;
  }
}

.andLabel {
  display: inline-block;
  text-align: center;
  width: 56px;
  line-height: 20px;
  background-color: #e2e2e2;
  border: 1px solid #5e5e5e;
  border-radius: 12px;
}

.disabled {
  border-color: #c9c9c9;
  color: #c9c9c9;
  cursor: not-allowed;
}

.hidden {
  display: none !important;
}

.textInput {
  width: 100% !important;
}

.addRuleButton {
  :global {
    .daas-ant-btn {
      height: auto;
      background: #ffffff;
      border: 1px solid #686868;
      border-radius: 6px;
      color: #686868;
      line-height: 22px;
      padding: 6px 12px;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

// .col {
//   &:nth-child(1) {
//     width: 80px;
//   }
//   &:nth-child(2) {
//     flex-grow: 1;
//   }
//   &:nth-child(3) {
//     width: 80px;
//   }
// }

@ant-prefix: daas-ant;