.page-data-model-item {
  & > .header {
    padding: 20px;
    min-height: 100px;
    position: relative;
  }
  & > .content {
    position: relative;
  }
  .daas-ant-tabs {
    overflow: visible;
  }
}

@ant-prefix: daas-ant;