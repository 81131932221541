.page-data-model-list {
  .wrapper-loading {
    .list {
      min-height: 500px;
      padding: 0px 20px;
      & > .item {
        @item-size: 260px;
        @item-padding: 20px;
        position: relative;
        display: inline-flex;
        flex-direction: column;
        width: 260px;
        height: 260px;
        overflow: scroll;
        margin-top: 10px;
        margin-right: 28px;
        border: 1px solid #cacaca;
        border-radius: 6px;
        &:hover {
          border: 1px solid #0038f3;
          cursor: pointer;
        }
        & > .name {
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
          word-break: break-word;
          padding: @item-padding @item-padding 0px @item-padding;
        }
        & > .description {
          flex-grow: 1;
          box-sizing: border-box;
          padding: 8px @item-padding;
          font-size: 16px;
          line-height: 22px;
          color: #4a4a4a;
          overflow: scroll;
        }
        & > .active-pipeline-count {
          padding-left: @item-padding;
          font-size: 16px;
          line-height: 36px;
          height: 40px;
          color: #46b04a;
        }
      }
    }
  }
}

@ant-prefix: daas-ant;