.settingTable {
  :global {
    .daas-ant-table {
      .daas-ant-table-tbody {
        .daas-ant-table-row-selected {
          & > td {
            background-color: #ffffff;
          }
        }
      }
    }

    .daas-ant-input {
      border: 1px solid #7c868f;
      border-radius: 4px;
    }

    // Modify the checkbox default style.

    .daas-ant-checkbox-indeterminate {
      .daas-ant-checkbox-inner::after {
        background-color: #002bf7;
      }
    }

    .daas-ant-checkbox-checked {
      &::after {
        border: 1px solid #002bf7;
      }

      .daas-ant-checkbox-inner {
        border-color: #002bf7;
        background-color: #ffffff;

        &::after {
          border-color: #002bf7;
        }
      }
    }

    .daas-ant-checkbox:hover {
      .daas-ant-checkbox-inner {
        border-color: #002bf7;
      }
    }

    .daas-ant-checkbox-input:focus {
      & + .daas-ant-checkbox-inner {
        border-color: #002bf7;
      }
    }
  }
}

@ant-prefix: daas-ant;