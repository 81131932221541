@import '~@/service/style/var.less';
@import '~@/service/style/reboot.less';
@import '~antd/dist/antd.less';

@body-padding: 8px;

#root {
  height: 100%;
  background-color: @primary-bg-color;
  // background-color: white;
  padding: @body-padding @body-padding 0px;
  & > .app {
    min-height: 100%;
    &.fix-height {
      height: 100%;
    }
    background-color: white;
  }
}

@ant-prefix: daas-ant;